.whatsapp-icon {
    position: fixed;
    bottom: 70px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    z-index: 999;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .whatsapp-icon i {
    text-align: center;
  }